import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, X } from 'lucide-react';

const CaseStudyCard = ({ icon: Icon, title, industry }) => (
  <motion.div 
    className="bg-white bg-opacity-5 p-6 rounded-lg backdrop-blur-md cursor-pointer hover:bg-opacity-10 transition-all duration-300"
    whileHover={{ scale: 1.05 }}
  >
    <div className="flex items-center mb-2">
      <div className="bg-blue-500 p-2 rounded-full mr-4">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h4 className="text-xl font-bold gradient-text">{title}</h4>
    </div>
    <p className="text-sm text-blue-300">{industry}</p>
    <ChevronRight className="text-blue-300 mt-4" />
  </motion.div>
);

const CaseStudyDetail = ({ study, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <motion.div 
      className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        ref={modalRef}
        className="bg-gradient-to-br from-indigo-900 to-black rounded-lg backdrop-blur-md max-w-2xl w-full relative flex flex-col max-h-[90vh] border border-blue-500"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
      >
        <div className="p-6 border-b border-blue-500 bg-black bg-opacity-30">
          <button 
            onClick={onClose} 
            className="absolute top-4 right-4 text-blue-300 hover:text-white transition-colors p-2"
            aria-label="Close"
          >
            <X size={24} />
          </button>
          <div className="flex items-center">
            <div className="bg-gradient-to-br from-blue-500 to-purple-600 p-3 rounded-full mr-4">
              <study.icon className="w-8 h-8 text-white" />
            </div>
            <div>
              <h3 className="text-2xl font-bold gradient-text mb-1">{study.title}</h3>
              <p className="text-blue-300">{study.industry}</p>
            </div>
          </div>
        </div>
        <div className="p-6 overflow-y-auto">
          {['Challenge', 'Solution', 'Outcome'].map((section, index) => (
            <div key={section} className="mb-6">
              <h4 className="text-lg font-bold text-blue-300 mb-2">{section}</h4>
              <p className="text-gray-300">
                {index === 0 ? study.challenge : index === 1 ? study.solution : study.outcome}
              </p>
            </div>
          ))}
        </div>
        <div className="p-6 border-t border-blue-500 bg-black bg-opacity-30">
          <button 
            onClick={onClose}
            className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 rounded hover:from-blue-600 hover:to-purple-700 transition-all duration-300 font-semibold"
          >
            Close
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

const CaseStudies = ({ studies }) => {
  const [selectedStudy, setSelectedStudy] = useState(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#case-studies') {
      const element = document.getElementById('case-studies');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <section id="case-studies" className="py-20 bg-gradient-to-b from-indigo-900 to-black">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl font-bold text-center mb-16 gradient-text"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Success Stories
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {studies.map((study, index) => (
            <div key={index} onClick={() => setSelectedStudy(study)}>
              <CaseStudyCard {...study} />
            </div>
          ))}
        </div>
      </div>
      <AnimatePresence>
        {selectedStudy && (
          <CaseStudyDetail 
            study={selectedStudy} 
            onClose={() => setSelectedStudy(null)} 
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default CaseStudies;