import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const navItems = [
    { name: 'Home', href: '/' },
    { name: 'Services', href: '/#services' },
    { name: 'Case Studies', href: '/#case-studies' },
    { name: 'Approach', href: '/#approach' },
    { name: 'About', href: '/#about' },
  ];

  const handleNavClick = (e, href) => {
    e.preventDefault();
    if (href === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (href.startsWith('/#')) {
      const id = href.substring(2); // Remove the '/#' from the beginning
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setIsMenuOpen(false);
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : '';
  }, [isMenuOpen]);

  return (
    <>
      <header 
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          scrolled ? 'bg-black bg-opacity-80 backdrop-blur-md' : 'bg-transparent'
        }`}
      >
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <a href="/" onClick={(e) => handleNavClick(e, '/')}>
              <img 
                src="/logo.png" 
                alt="Fulkerson Advisors Logo" 
                className="h-10 w-auto"
                width="40"
                height="40"
              />
            </a>
            
            <nav className="hidden lg:block" aria-label="Main Navigation">
              <ul className="flex space-x-8 items-center">
                {navItems.map((item) => (
                  <li key={item.name}>
                    <a 
                      href={item.href} 
                      className="text-white hover:text-blue-300 transition duration-300"
                      onClick={(e) => handleNavClick(e, item.href)}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
                <li>
                  <a 
                    href="/#calendly" 
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all duration-300"
                    onClick={(e) => handleNavClick(e, '/#calendly')}
                  >
                    Schedule Strategy Session
                  </a>
                </li>
              </ul>
            </nav>

            <button onClick={toggleMenu} className="lg:hidden text-white z-50" aria-label="Toggle mobile menu">
              <Menu size={24} />
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      <div 
        className={`fixed inset-0 bg-gradient-to-br from-indigo-900 to-black z-50 transition-opacity duration-300 lg:hidden ${
          isMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden={!isMenuOpen}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center p-6 border-b border-blue-500 bg-black bg-opacity-30">
            <a href="/" onClick={(e) => handleNavClick(e, '/')}>
              <img 
                src="/logo.png" 
                alt="Fulkerson Advisors Logo" 
                className="h-10 w-auto"
                width="40"
                height="40"
              />
            </a>
            <button onClick={toggleMenu} className="text-blue-300 hover:text-white transition-colors" aria-label="Close mobile menu">
              <X size={24} />
            </button>
          </div>
          <nav className="flex-grow flex items-center justify-center p-6 overflow-y-auto">
            <ul className="space-y-6 text-center">
              {navItems.map((item) => (
                <li key={item.name}>
                  <a 
                    href={item.href}
                    className="text-white hover:text-blue-300 transition duration-300 text-xl block"
                    onClick={(e) => handleNavClick(e, item.href)}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
              <li>
                <a 
                  href="/#calendly"
                  className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all duration-300 text-lg inline-block mt-4"
                  onClick={(e) => handleNavClick(e, '/#calendly')}
                >
                  Book a Call
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;