import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Calendly = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.onload = () => setIsLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/fulkerson-advisors/exploratory-strategy-call?hide_gdpr_banner=1&background_color=1e1e1e&text_color=ffffff&primary_color=3b82f6',
        parentElement: document.getElementById('calendly-inline-widget'),
        prefill: {},
        utm: {}
      });
    }
  }, [isLoaded]);

  return (
    <section id="calendly" className="py-20 bg-gradient-to-b from-indigo-900 to-black">
      <div className="container mx-auto px-4">
        <motion.div 
          className="text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">
            Schedule Your GenAI Strategy Session
          </h2>
          <p className="text-xl mb-8 text-blue-300 font-light max-w-2xl mx-auto">
            Take the first step towards transforming your GenAI mandate into actionable strategy. Book a call with Christian to discuss your unique challenges and opportunities.
          </p>
        </motion.div>
        <motion.div
          className="bg-[#1e1e1e] rounded-lg overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          {isLoaded ? (
            <div 
              id="calendly-inline-widget"
              style={{ minWidth: '320px', height: '750px' }}
            />
          ) : (
            <div className="h-[750px] flex items-center justify-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default Calendly;