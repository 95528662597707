import React from 'react';
import { motion } from 'framer-motion';
import { Linkedin, Twitter } from 'lucide-react';

const Footer = () => (
  <footer className="bg-gradient-to-b from-indigo-900 to-black text-white py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <h3 className="text-xl font-bold mb-4 gradient-text">Fulkerson Advisors</h3>
          <p className="font-light">PO Box 410127</p>
          <p className="font-light">Cambridge, MA 02141</p>
        </motion.div>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.1 }}>
          <h3 className="text-xl font-bold mb-4 gradient-text">Contact</h3>
          <p className="font-light">info@fulkersonadvisors.com</p>
          <p className="font-light">(617) 751-9068</p>
        </motion.div>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>
          <h3 className="text-xl font-bold mb-4 gradient-text">Follow Us</h3>
          <div className="flex space-x-4">
            <motion.a 
              href="https://www.linkedin.com/company/fulkerson-advisors" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-blue-300 transition duration-300"
              whileHover={{ scale: 1.2 }}
            >
              <Linkedin size={24} />
            </motion.a>
            <motion.a 
              href="https://twitter.com/fulkadvisors" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-blue-300 transition duration-300"
              whileHover={{ scale: 1.2 }}
            >
              <Twitter size={24} />
            </motion.a>
          </div>
        </motion.div>
      </div>
      <motion.div 
        className="text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <motion.a 
          href="#calendly" 
          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all duration-300 inline-block mb-8"
          whileHover={{ scale: 1.05 }}
        >
          Schedule Strategy Session
        </motion.a>
        <p className="text-gray-400 font-light">&copy; 2024 Fulkerson Advisors. All rights reserved.</p>
      </motion.div>
    </div>
  </footer>
);

export default Footer;