import React from 'react';
import { motion } from 'framer-motion';

const About = () => (
  <section id="about" className="py-20 bg-gradient-to-b from-indigo-900 to-black">
    <div className="container mx-auto px-4">
      <motion.h2 
        className="text-4xl font-bold text-center mb-16 gradient-text"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        About Us
      </motion.h2>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <motion.div 
          className="md:w-1/3 mb-8 md:mb-0"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <img src="/christian.jpg" alt="Christian Adib" className="rounded-lg shadow-xl w-full" />
        </motion.div>
        <motion.div 
          className="md:w-2/3 md:pl-12"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h3 className="text-3xl font-bold mb-4 gradient-text">Christian Adib</h3>
          <p className="text-xl mb-4 text-blue-300">Founder and Principal Consultant</p>
          <p className="text-gray-300 mb-4 font-light leading-relaxed">
            With over a decade of experience bridging technology and business strategy, Christian has guided numerous Fortune 500 executives through successful GenAI transformations. His unique blend of technical expertise and executive communication skills enables the translation of complex AI concepts into clear, actionable strategies that drive measurable business outcomes.
          </p>
          <p className="text-gray-300 font-light leading-relaxed">
            Christian's approach combines deep industry knowledge with a practical understanding of GenAI's potential, ensuring that your organization not only adopts cutting-edge technology but does so in a way that creates sustainable competitive advantage and tangible ROI.
          </p>
        </motion.div>
      </div>
    </div>
  </section>
);

export default About;