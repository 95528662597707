import React from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <section className="relative min-h-screen flex items-center overflow-hidden bg-gradient-to-b from-indigo-900 to-black">
      <div className="absolute inset-0 bg-[url('/public/images/grid.svg')] opacity-20"></div>
      <div className="container mx-auto px-4 relative z-10 py-20">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8 leading-tight gradient-text">
            Elevate Your<br />GenAI Strategy
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl mb-12 text-blue-300 max-w-2xl mx-auto font-light">
            Transform AI vision into actionable strategies.
          </p>
          <motion.a 
            href="#calendly" 
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-8 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all duration-300 inline-block shadow-lg hover:shadow-xl transform hover:-translate-y-1 text-lg font-medium"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Schedule Strategy Session
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;