import React from 'react';
import { motion } from 'framer-motion';
import { Search, Map, Presentation, Rocket, LineChart } from 'lucide-react';

const ApproachStep = ({ icon: Icon, title, description, index }) => (
  <motion.div 
    className="bg-white bg-opacity-5 p-6 rounded-lg backdrop-blur-md"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
  >
    <div className="flex items-center mb-4">
      <div className="bg-blue-500 p-2 rounded-full mr-4">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h3 className="text-xl font-bold gradient-text">{title}</h3>
    </div>
    <p className="text-gray-300 font-light">{description}</p>
  </motion.div>
);

const Approach = () => (
  <section id="approach" className="py-20 bg-gradient-to-b from-indigo-900 to-black">
    <div className="container mx-auto px-4">
      <motion.h2 
        className="text-4xl font-bold text-center mb-16 gradient-text"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Our Executive-Focused Approach
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          { icon: Search, title: 'Executive Discovery', description: 'In-depth executive interviews and workshops.' },
          { icon: Map, title: 'Strategic Roadmapping', description: 'Developing a comprehensive GenAI roadmap.' },
          { icon: Presentation, title: 'Stakeholder Alignment', description: 'Facilitating cross-functional alignment.' },
          { icon: Rocket, title: 'Phased Implementation', description: 'Executing the strategy in manageable phases.' },
          { icon: LineChart, title: 'Impact Measurement', description: 'Implementing robust metrics and reporting.' }
        ].map((step, index) => (
          <ApproachStep key={index} {...step} index={index} />
        ))}
      </div>
    </div>
  </section>
);

export default Approach;