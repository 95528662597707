import React from 'react';
import { motion } from 'framer-motion';
import { Lightbulb, Users, Code, DollarSign } from 'lucide-react';

const ServiceCard = ({ title, description, icon: Icon, price }) => (
  <motion.div 
    className="bg-white bg-opacity-5 p-8 rounded-lg backdrop-blur-md hover:bg-opacity-10 transition-all duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="w-12 h-12 text-blue-400 mb-4" />
    <h3 className="text-2xl font-bold mb-4 gradient-text">{title}</h3>
    <p className="text-gray-300 font-light mb-4">{description}</p>
    {price && <p className="text-blue-300 font-semibold">{price}</p>}
  </motion.div>
);

const Services = () => (
  <section id="services" className="py-20 bg-gradient-to-b from-indigo-900 to-black">
    <div className="container mx-auto px-4">
      <motion.h2 
        className="text-4xl font-bold text-center mb-16 gradient-text"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Our Services
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <ServiceCard
          title="Strategic Consulting"
          description="On-demand guidance to enhance your team's AI capabilities, streamline processes, and drive growth. Includes access to expert networks and hiring assistance."
          icon={Lightbulb}
        />
        <ServiceCard
          title="Comprehensive Consulting"
          description="Hands-on collaboration with your team, including prototyping, production support, model optimization, and custom tool development. Ideal for teams looking to accelerate their AI development process."
          icon={Users}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <ServiceCard
          title="Custom Development"
          description="Tailored AI solutions and infrastructure development to meet your specific needs and challenges."
          icon={Code}
        />
        <ServiceCard
          title="Project-Based Consulting"
          description="Comprehensive AI strategy and implementation over a 3-month minimum period, perfect for organizations looking for transformative results."
          icon={DollarSign}
        />
      </div>
      <motion.p 
        className="text-center text-blue-300 mt-16 font-light"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        Startup-friendly pricing available. Contact us to discuss your unique needs and explore how we can tailor our services to your budget and goals.
      </motion.p>
    </div>
  </section>
);

export default Services;