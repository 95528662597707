import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import CaseStudies from './components/CaseStudies';
import Approach from './components/Approach';
import About from './components/About';
import Calendly from './components/Calendly';
import Footer from './components/Footer';
import { Database, BookOpen, Briefcase, FileText, LineChart, Phone } from 'lucide-react';

const App = () => {
  const caseStudies = [
    {
      icon: Database,
      title: "SQL Chatbot for Enhanced Data Interactions",
      industry: "Technology / Data Management",
      challenge: "A leading technology company was struggling with data accessibility. Their complex Redshift database was a treasure trove of insights, but it remained largely untapped due to the technical expertise required to query it effectively.",
      solution: "We developed a sophisticated SQL chatbot that acts as an intelligent intermediary between users and the Redshift database. This AI-powered solution leverages advanced natural language processing techniques to interpret user queries, regardless of their technical background.",
      outcome: "The implementation of the SQL chatbot transformed the company's data interaction landscape. Non-technical staff from various departments can now easily extract valuable insights from the database without requiring SQL knowledge."
    },
    {
      icon: BookOpen,
      title: "AI-Powered Content Generation for EdTech",
      industry: "Education Technology",
      challenge: "An innovative EdTech startup was facing a critical bottleneck in their content creation process. As they aimed to provide personalized learning experiences, the demand for diverse, high-quality educational materials was outpacing their ability to produce content using traditional methods.",
      solution: "We devised a comprehensive GenAI solution tailored for educational content generation. This involved developing sophisticated prompting techniques optimized for creating various types of educational materials, including lesson plans, quizzes, and explanatory texts.",
      outcome: "The GenAI solution revolutionized the startup's content creation capabilities. Content creators can now focus on high-level curriculum design and quality control, while the AI handles the bulk of initial content generation."
    },
    {
      icon: Briefcase,
      title: "GenAI Strategy for Enterprise Software Startup",
      industry: "Enterprise Software",
      challenge: "An enterprise software startup was at a critical juncture in their growth journey. They recognized the transformative potential of Generative AI but lacked a coherent strategy to integrate it into their product offerings and internal processes.",
      solution: "We collaborated closely with the startup's leadership to develop a comprehensive GenAI strategy. This involved a deep analysis of their current product suite, market positioning, and internal capabilities.",
      outcome: "The GenAI strategy provided the startup with a clear direction for innovation and growth. It enabled them to prioritize GenAI initiatives that aligned closely with their business objectives and market demands."
    },
    {
      icon: FileText,
      title: "Unstructured Data Mapping for Streamlined Integration",
      industry: "Data Integration Services",
      challenge: "A data integration service provider was grappling with the increasing volume and variety of unstructured data their clients needed to process. Their existing methods were time-consuming and often required manual intervention, leading to delays and inconsistencies in data integration.",
      solution: "We developed an advanced pipeline leveraging GenAI technologies to automate the mapping of unstructured data to the company's internal data schema. This solution incorporated natural language processing and machine learning algorithms to understand, categorize, and structure incoming data from various sources.",
      outcome: "The implementation of this GenAI-powered pipeline transformed the company's data integration capabilities. The time required for data mapping and integration was significantly reduced, allowing the company to handle a much larger volume of data with greater speed and accuracy."
    },
    {
      icon: LineChart,
      title: "Equity Index Strategy Backtesting Web Application",
      industry: "Financial Services / Pension Fund",
      challenge: "A prominent pension fund was looking to optimize its investment strategies, particularly in equity index investments. They needed a robust way to evaluate potential strategies by backtesting them against historical data.",
      solution: "We developed a sophisticated web application specifically designed for backtesting equity index strategies. This application leveraged GenAI to process and analyze vast amounts of historical financial data.",
      outcome: "The backtesting web application revolutionized the pension fund's approach to strategy development and evaluation. Investment managers can now rapidly test and refine their ideas, leading to more robust and well-validated investment strategies."
    },
    {
      icon: Phone,
      title: "LLM Agent for Medical Device Customer Support",
      industry: "Healthcare / Medical Devices",
      challenge: "A medical device company was facing increasing pressure on their customer support team. They needed to provide timely, accurate support to patients using their devices, but the volume of inquiries was overwhelming their human staff.",
      solution: "We created an advanced LLM (Large Language Model) agent capable of conducting voice calls with patients. This AI-powered solution was integrated with the company's product documentation and support databases, enabling it to provide accurate, up-to-date information.",
      outcome: "The implementation of the LLM agent significantly enhanced the company's customer support capabilities. It successfully handles a large volume of routine inquiries and follow-ups, freeing up human agents to focus on more complex cases."
    }
  ];

  return (
    <div className="font-sans text-white bg-black">
      <Header />
      <main>
        <Hero />
        <Services />
        <CaseStudies studies={caseStudies} />
        <Approach />
        <About />
        <Calendly />
      </main>
      <Footer />
    </div>
  );
};

export default App;